import React, { CSSProperties, ReactNode } from "react"

interface StackProps {
  children: ReactNode
  padding?: string
  gap?: string
  align?: "center" | "flex-start" | "flex-end"
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
}

const Stack: React.FC<StackProps> = ({
  children,
  padding,
  gap,
  align,
  justify,
}) => {
  const stackStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    padding: padding || "0",
    width: "100%",
    gap: gap || "10px",
    alignItems: align || "center",
    justifyContent: justify || "center",
  }
  return <div style={stackStyle}>{children}</div>
}
export default Stack
