import React, { useEffect, useState } from "react"
import Container from "../reusable-components/container/container"
import Text from "../reusable-components/text/text"
import "./section-services.style.css"
import Box from "../reusable-components/box/box"
import Divider from "../reusable-components/divider/divider"
import { ServicesComponent } from "./services-component"
import { handleResize } from "../../utils/utils"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

export default function SectionServices() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isTablet = windowWidth < 850
  const isMobile = windowWidth < 500
  const isMobileM = windowWidth < 390
  const { t } = useTranslation()

  useEffect(() => {
    handleResize(setWindowWidth)
    i18next.changeLanguage("ro")
  }, [])

  return (
    <div className="background-container-services" id="services">
      <Container>
        <Box
          flexDirection="column"
          justify="space-evenly"
          height="100%"
          padding={isTablet ? "6rem 0 5rem 0" : "0"}
        >
          <Box
            flexDirection="column"
            justify="space-around"
            height={isTablet ? "150px" : "17%"}
            padding={isTablet ? "0 0 3rem 0" : "0"}
          >
            <Text
              color="#363636"
              size={isMobile ? (isMobileM ? "1.2rem" : "1.4rem") : "1.75rem"}
              fontWeight={240}
              lineHeight={isMobileM ? "1.7rem" : "2.2rem"}
            >
              {t("section_services_title_1")}
              <br></br>
              {t("section_services_title_2")}
            </Text>
            <Divider
              thickness="1px"
              color="#61C6F4"
              margin={
                isTablet
                  ? isMobileM
                    ? "0.5rem 0 0.5rem 0"
                    : "1rem 0 1rem 0"
                  : "2rem 0 0 0"
              }
            />
          </Box>
          <ServicesComponent height={isTablet ? "60%" : "45%"} />
        </Box>
      </Container>
    </div>
  )
}
