import React, { useState } from "react"
import "./card-image.style.scss"

interface ImageComponentProps {
  width?: string
  image: string
  name: string
  jobName: string
  description: string
}

const CardImage: React.FC<ImageComponentProps> = ({
  image,
  name,
  jobName,
  description,
  width,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      className={"card"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ width: width, height: 350 }}
    >
      <img src={image} alt={name} />
      {isHovered ? (
        <div className="info">
          <h6>{name}</h6>
          <p className="name-job-custom">{jobName}</p>
          <p>{description}</p>
        </div>
      ) : null}
      <div className="main-info">
        <h6>{name}</h6>
        <p className="name-job-custom">{jobName}</p>
      </div>
    </div>
  )
}

export default CardImage
