import React, { useEffect, useState } from "react"
import { primaryColor } from "../../../constants"
import Card from "../card/card"
import Text from "../text/text"
import Stack from "../stack/stack"
import "./slider.style.scss"
import { handleResize } from "../../../utils/utils"
import { useTranslation } from "react-i18next"

export default function Slider() {
  const currentYear = new Date().getFullYear()
  const startYear = new Date(2021, 6, 12).getFullYear()
  const { t } = useTranslation()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile = windowWidth < 650

  useEffect(() => {
    handleResize(setWindowWidth)
  }, [])

  return (
    <div className="container-slider">
      <input type="radio" name="slider" id="item-1" defaultChecked />
      <input type="radio" name="slider" id="item-2" />
      <input type="radio" name="slider" id="item-3" />
      <div className="cards">
        <label className="card-slider" htmlFor="item-1" id="song-1">
          <Card
            background="#def2faf5"
            border="1px solid #FBFCFE"
            padding="2rem"
            height={isMobile ? "400px" : "320px"}
          >
            <Stack align={isMobile ? "center" : "flex-start"}>
              <img
                alt="asterix-icon"
                src="/asterix.png"
                height="auto"
                width="70px"
              />
              <Text
                size="1.5rem"
                fontWeight={650}
                align={isMobile ? "center" : "left"}
                color={primaryColor}
                margin="2rem 0 1rem 0"
              >
                {t("section_benefits_card_1_title")}
              </Text>
              <Text
                fontWeight={350}
                align={isMobile ? "center" : "left"}
                lineHeight="22px"
                color="#363636"
              >
                {t("section_benefits_card_1_content")}
              </Text>
            </Stack>
          </Card>
        </label>
        <label className="card-slider" htmlFor="item-2" id="song-2">
          <Card
            background="#def2faf5"
            border="1px solid #FBFCFE"
            padding="2rem"
            height={isMobile ? "400px" : "320px"}
          >
            <Stack align={isMobile ? "center" : "flex-start"}>
              <img
                alt="code-tag-icon"
                src="/codeTag.png"
                height="auto"
                width="120px"
              />
              <Text
                size="1.5rem"
                fontWeight={650}
                align={isMobile ? "center" : "left"}
                color={primaryColor}
                margin="2rem 0 1rem 0 "
              >
                {t("section_benefits_card_2_title")}
              </Text>
              <Text
                fontWeight={350}
                align={isMobile ? "center" : "left"}
                lineHeight="22px"
                color="#363636"
              >
                {t("section_benefits_card_2_content")}
              </Text>
            </Stack>
          </Card>
        </label>
        <label className="card-slider" htmlFor="item-3" id="song-3">
          <Card
            background="#def2faf5"
            border="1px solid #FBFCFE"
            padding="2rem"
            height={isMobile ? "400px" : "320px"}
          >
            <Stack align={isMobile ? "center" : "flex-start"}>
              <img
                alt="cloud-icon"
                src="/cloud.png"
                height="auto"
                width="100px"
              />
              <Text
                size="1.5rem"
                fontWeight={650}
                align={isMobile ? "center" : "left"}
                color={primaryColor}
                margin="2rem 0 1rem 0 "
              >
                {t("section_benefits_card_3_title")}
              </Text>
              <Text
                fontWeight={350}
                align={isMobile ? "center" : "left"}
                lineHeight="22px"
                color="#363636"
              >
                {t("section_benefits_card_3_content_1")}{" "}
                {currentYear - startYear}{" "}
                {t("section_benefits_card_3_content_2")}
              </Text>
            </Stack>
          </Card>
        </label>
      </div>
    </div>
  )
}
