import React, { useEffect, useState } from "react"
import Box from "../reusable-components/box/box"
import Text from "../reusable-components/text/text"
import { Flex } from "../reusable-components/flex/flex"
import "./footer.style.scss"
import { primaryColor } from "../../constants"
import Stack from "../reusable-components/stack/stack"
import Container from "../reusable-components/container/container"
import { ContactForm } from "./contact-form"
import { handleResize } from "../../utils/utils"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

export default function Footer() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { t } = useTranslation()

  useEffect(() => {
    handleResize(setWindowWidth)
    i18next.changeLanguage("ro")
  }, [])
  const isTablet = windowWidth < 800
  const isMobile = windowWidth < 450

  return (
    <div className="background-container-footer" id="footer">
      <Container>
        <Flex
          flexDirection={isTablet ? "column" : "row"}
          align="flex-start"
          justify="space-between"
          width="100%"
          gap={isTablet ? "5rem" : "3rem"}
        >
          <ContactForm widthForm={isTablet ? "100%" : "55%"} />
          <Box width={isTablet ? "100%" : "40%"} flexDirection="column">
            <Text
              size="2.5rem"
              fontWeight={700}
              color={primaryColor}
              margin="0 0 2rem 0"
              align={isTablet ? "center" : "left"}
              width="100%"
            >
              {t("footer_title_contact")}
            </Text>
            <Stack gap="25px" align={isMobile ? "center" : "flex-start"}>
              <Box
                gap="1rem"
                align="center"
                flexDirection={isMobile ? "column" : "row"}
              >
                <img
                  alt="phone-icon"
                  src="/phone-icon.png"
                  width={25}
                  height={25}
                />
                <Text width="100%" align="left">
                  <a
                    href="tel:+40745193485"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "1.4rem",
                      fontWeight: 200,
                    }}
                  >
                    +40 745 193 485
                  </a>
                </Text>
              </Box>
              <Box
                gap="1rem"
                align="center"
                flexDirection={isMobile ? "column" : "row"}
              >
                <img
                  alt="email-icon"
                  src="/email-icon.png"
                  width={25}
                  height={25}
                />
                <Text width="100%" align="left">
                  <a
                    href="mailto:admin@evosa.ro"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "1.4rem",
                      fontWeight: 200,
                    }}
                  >
                    admin@evosa.ro
                  </a>
                </Text>
              </Box>
              <Box
                gap="1rem"
                align="center"
                flexDirection={isMobile ? "column" : "row"}
              >
                <img
                  alt="location-icon"
                  src="/location-icon.png"
                  width={25}
                  height={25}
                />
                <Text
                  width="100%"
                  align={isMobile ? "center" : "left"}
                  lineHeight="28px"
                >
                  <a
                    href="https://maps.app.goo.gl/UVFRGDUmq2gGygsc7"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "1.4rem",
                      fontWeight: 200,
                    }}
                    rel="noreferrer"
                  >
                    Str. Gheorghe Lazar, Nr. 9, Office HQE-203, Timișoara, Timiș
                  </a>
                </Text>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Container>
    </div>
  )
}
