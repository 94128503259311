import React from "react"

import "./App.scss"
import Footer from "./components/footer/footer"
import { SectionAboutEvosa } from "./components/section-about-evosa/section-about-evosa"
import { SectionBenefits } from "./components/section-benefits/section-benefits"
import SectionClients from "./components/section-clients/section-clients"
import SectionOne from "./components/section-one/section-one"
import SectionServices from "./components/section-services/section-services"
import SectionWhatMeansEvosa from "./components/section-what-means-evosa/section-what-means-evosa"
import "./i18n"

function App() {
  return (
    <div className="App">
      <div id="container">
        <SectionOne />
        <SectionServices />
        <SectionBenefits />
        <SectionAboutEvosa />
        <SectionWhatMeansEvosa />
        <SectionClients />
        <Footer />
      </div>
    </div>
  )
}

export default App
