import i18next from "i18next"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { primaryColor } from "../../constants"
import { handleResize } from "../../utils/utils"
import Box from "../reusable-components/box/box"
import CardImage from "../reusable-components/card-image/card-image"
import { Flex } from "../reusable-components/flex/flex"
import Text from "../reusable-components/text/text"

const ComponentTeam: FunctionComponent<{ height: string }> = ({ height }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const isLaptopS = windowWidth < 1100
  const isMobile = windowWidth < 460

  useEffect(() => {
    handleResize(setWindowWidth)
    i18next.changeLanguage("ro")
  }, [])

  return (
    <Box height={height} width="100%" flexDirection="column" justify="flex-end">
      <Text
        fontWeight={700}
        color={primaryColor}
        size="3rem"
        margin="0 0 3rem 0 "
      >
        {t("section_team_title")}
      </Text>
      <Flex
        flexDirection={isLaptopS ? "column" : "row"}
        width="100%"
        height="100%"
        justify="space-between"
        gap="10px"
      >
        <Flex
          flexDirection={isMobile ? "column" : "row"}
          width="100%"
          height="100%"
          justify="space-between"
          gap="10px"
        >
          <CardImage
            width={isMobile ? "auto" : "100%"}
            image={"sv.png"}
            name={"Silviu Vlăduț"}
            jobName={"MANAGING PARTNER"}
            description={t("section_team_description_silviu")}
          />
          <CardImage
            width={isMobile ? "auto" : "100%"}
            image={"am.png"}
            name={"Anca Miloș"}
            jobName={"MANAGING PARTNER"}
            description={t("section_team_description_anca")}
          />
        </Flex>
        <Flex
          flexDirection={isMobile ? "column" : "row"}
          width="100%"
          height="100%"
          justify="space-between"
          gap="10px"
        >
          <CardImage
            width={isMobile ? "auto" : "100%"}
            image={"lv.png"}
            name={"Lorena Vaidasigan"}
            jobName={"Junior Frontend Developer"}
            description={t("section_team_description_lorena")}
          />
          <CardImage
            width={isMobile ? "auto" : "100%"}
            image={"cl.png"}
            name={"Călin Lăzuran"}
            jobName={"Frontend Developer"}
            description={t("section_team_description_calin")}
          />
        </Flex>
      </Flex>
      <Text
        size="1.6rem"
        fontWeight={200}
        lineHeight={isMobile ? "30px" : "auto"}
        margin="4rem 0 0 0"
      >
        {t("section_team_motto")}
      </Text>
    </Box>
  )
}
export default ComponentTeam
