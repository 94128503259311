import React, { CSSProperties } from "react"
import { blue } from "../../../constants"

interface DividerProps {
  length?: string
  color?: string
  thickness?: string
  margin?: string
  orientation?: "vertical" | "horizontal"
}

const Divider: React.FC<DividerProps> = ({
  length = "100%",
  color = blue,
  thickness = "1px",
  orientation = "vertical",
  margin = "0",
}) => {
  const lineStyle: CSSProperties = {
    backgroundColor: color,
    margin,
  }
  if (orientation === "vertical") {
    lineStyle.width = thickness
    lineStyle.height = length
  } else {
    lineStyle.width = length
    lineStyle.height = thickness
  }

  return <div style={lineStyle}></div>
}

export default Divider
