import "./buttons.style.scss"
import React, { ReactNode } from "react"

interface CustomButtonProps {
  variant: "primary" | "secondary" | "outline"
  size?: "small" | "normal" | "large"
  fontSize?: string
  width?: string
  height?: string
  borderRadius?: string
  backgroundColor?: string
  durationTransition?: number
  padding?: string
  margin?: string
  textColor?: string
  fontWeight?: number
  lineHeight?: string
  type?: "button" | "submit" | "reset"
  onClick?: () => void
  children: ReactNode
}

export const Button: React.FC<CustomButtonProps> = ({
  variant,
  size,
  fontSize,
  borderRadius,
  onClick,
  children,
  width,
  height,
  padding,
  margin,
  textColor,
  fontWeight,
  lineHeight,
  type = "button",
}) => {
  const buttonStyle = {
    margin: margin || "0",
    padding: padding || "5px",
    borderRadius: borderRadius || "5px",
    cursor: "pointer",
    size: size || "normal",
    fontSize: fontSize || "15px",
    width: width || "auto",
    height: height || "auto",
    color: textColor || "white",
    fontWeight: fontWeight || 500,
    lineHeight: lineHeight || "auto",
  }
  const buttonClasses = `button ${variant}`

  return (
    <button
      className={buttonClasses}
      style={buttonStyle}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  )
}
