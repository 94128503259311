import i18next from "i18next"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { imagesClients, primaryColor } from "../../constants"
import Box from "../reusable-components/box/box"
import Divider from "../reusable-components/divider/divider"
import { InfiniteLooper } from "../reusable-components/infinite-looper/infinite-looper"
import Text from "../reusable-components/text/text"
import "./section-clients.style.css"

export default function SectionClients() {
  const { t } = useTranslation()
  useEffect(() => {
    i18next.changeLanguage("ro")
  }, [])

  return (
    <div className="background-container-clients">
      <Box
        flexDirection="column"
        justify="center"
        align="center"
        height="100%"
        width="100%"
      >
        <Text
          size="3rem"
          fontWeight={700}
          color={primaryColor}
          margin="0 0 5rem 0"
        >
          {t("section_clients_title")}
        </Text>
        <InfiniteLooper speed={40}>
          {imagesClients.map((image, index) => (
            <Box
              key={index}
              width="100%"
              flexDirection="column"
              padding="0 35px 0 35px"
            >
              <div>
                <img
                  src={image.path}
                  height="90"
                  width="200"
                  alt={image.name}
                />
              </div>
              <Divider
                orientation="horizontal"
                length="20%"
                margin="1rem 0rem 1rem 0rem"
              />
              <Text fontWeight={350} color="black">
                {image.domain}
              </Text>
            </Box>
          ))}
        </InfiniteLooper>
      </Box>
    </div>
  )
}
