import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDectector from "i18next-browser-languagedetector"

i18next
  .use(initReactI18next)
  .use(LanguageDectector)
  .init({
    resources: {
      ro: {
        translation: {
          navbar_btn_1: "Soluții Personalizate",
          navbar_btn_2: "Povestea Noastră",
          navbar_btn_3: "Contactează-ne",
          navbar_btn_offer: "Cere ofertă",
          section_services_title_1:
            "Dezvoltăm viitorul digital al afacerii tale",
          section_services_title_2: "cu experiență și inovație la fiecare pas!",
          section_services_subtitle_1: "Când ai nevoie",
          section_services_subtitle_2: "de servicii pentru:",
          section_services_service_1: "Centralizare de date",
          section_services_service_2: "Traking de procese",
          section_services_service_3: "Evaluarea proceselor",
          section_services_service_4: "E-Commerce",
          section_services_service_5:
            "Simplificarea procesului de generare ofertă contracte",
          section_benefits_title: "Cu evosa beneficiezi",
          section_benefits_card_1_title: "Consultanță",
          section_benefits_card_1_content:
            "Oferim o gamă largă de soluții software, creăm astfel încât să se potrivească nevoilor afacerii tale. Ne străduim să oferim servicii de cea mai înaltă calitate, bazate pe o abordare centrată pe client și pe o colaborare sinergică între membrii echipei noastre.",
          section_benefits_card_2_title: "Dezvoltare Web",
          section_benefits_card_2_content:
            "Utilizând doar cele mai noi soluții cu tehnologii precum React JS, Node JS și TypeScript, oferim rezultate în industrii precum audit financiar, retail, fashion sau aplicații interne, inclusiv aplicații web personalizate & securizate.",
          section_benefits_card_3_title: "Servicii Cloud",
          section_benefits_card_3_content_1: "Cu",
          section_benefits_card_3_content_2:
            " ani experiență, echipa evosa are capacitatea de a livra servicii de Sap Cloud, AWS Cloud, Google Cloud și orice alte servicii cloud, adapate pentru afacerea ta.",
          section_procedure_title: "Procedura evosa",
          section_procedure_step_1: "Înțelegerea Proiectului",
          section_procedure_step_2: "Conturarea Soluției",
          section_procedure_step_3: "Propunerea și Prezentarea Soluției",
          section_procedure_step_4: "Dezvoltarea Soluției",
          section_procedure_step_5:
            "Integrarea în ecosistem și Lansarea Soluției",
          section_procedure_step_6: "Mentenanța Soluție",
          section_team_title: "Echipa evosa",
          section_team_description_silviu:
            "În calitate de consultant și dezvoltator software pasionat și proactiv, ce aduce soluții inovatoare mereu, cu experiență în dezvoltarea web și soluții cloud. Colaborez eficient în echipe și sunt mereu în căutare de oportunități de învățare și creștere personală.",
          section_team_description_anca:
            "Consultant Frontend Strategic. Expertiză în optimizarea experiențelor clienților prin utilizarea instrumentelor avansate. Promovez medii colaborative pentru inovație continuă.",
          section_team_description_lorena:
            "În calitate de junior web developer, sunt motivată, deschisă la minte, o jucătoare entuziastă în echipă și mereu dispusă să accept noi provocări și perspective. În plus, am o pasiune deosebită pentru designul web și caut mereu să-mi îmbunătățesc abilitățile și cunoștințele în acest domeniu.",
          section_team_description_calin:
            "În calitate de dezvoltator UI/UX, sunt condus de o pasiune neobosită pentru crearea unor experiențe de utilizator remarcabile. Cu o etică de muncă puternică și o atenție deosebită la detalii, abordez în mod constant provocări complexe și găsesc soluții inovatoare care depășesc cerințele proiectului.",
          section_team_motto:
            "O echipă mică, cu vise mari și în curând cu mai mulți membri diverși în echipă.",
          section_what_means_title: "Evosa înseamnă",
          section_what_means_subtitle:
            "Echipă profesionistă - Servicii de calitate - Înțelegerea nevoilor clientului",
          section_what_means_button: "Transformă probleme în soluții web",
          section_clients_title: "Clienții noștri",
          footer_title_offer: "Cere o ofertă",
          footer_title_contact: "Contactează-ne",
          footer_subtitle_offer:
            "Completează formularul și un specialist evosa te va contacta în cel mai scurt timp. Mulțumim.",
          form_name: "Nume",
          form_domains: "Domeniu",
          form_domain_1: "Afaceri și productivitate",
          form_domain_2: "Sănătate și wellness",
          form_domain_3: "Media și divertisment",
          form_domain_4: "E-commerce",
          form_domain_5: "Imobiliare",
          form_domain_6: "Turism și călătorii",
          form_domain_7: "Resurse umane",
          form_domain_8: "Social media",
          form_domain_9: "Transport și logistică",
          form_domain_10: "Educație",
          form_domain_11: "Alt domeniu",
          form_email: "E-mail",
          form_phone: "Telefon",
          form_subject: "Subiect",
          form_message: "Mesajul tău aici...",
          error_message: "Te rog să completezi toate câmpurile.",
        },
      },
      en: {
        translation: {
          navbar_btn_1: "Custom Solutions",
          navbar_btn_2: "Our Story",
          navbar_btn_3: "Contact Us",
          navbar_btn_offer: "Ask For Offer",
          section_services_title_1:
            "Developing the digital future of your business",
          section_services_title_2:
            "with experience and innovation at every step!",
          section_services_subtitle_1: "When you need",
          section_services_subtitle_2: "services for:",
          section_services_service_1: "Data centralization",
          section_services_service_2: "Process tracking",
          section_services_service_3: "Process evaluation",
          section_services_service_4: "E-Commerce",
          section_services_service_5:
            "Simplification of the contract offer generation process",
          section_benefits_title: "With evosa you benefit",
          section_benefits_card_1_title: "Consulting",
          section_benefits_card_1_content:
            "We offer a wide range of software solutions, designed to suit your business needs. We strive to provide the highest quality service based on a customer-centric approach and synergistic collaboration between our team members.",
          section_benefits_card_2_title: "Web Development",
          section_benefits_card_2_content:
            "Using only the latest solutions with technologies such as React JS, Node JS and TypeScript, we deliver results in industries such as financial auditing, retail, fashion or in-house applications, including custom & secure web applications.",
          section_benefits_card_3_title: "Cloud Services",
          section_benefits_card_3_content_1: "With",
          section_benefits_card_3_content_2:
            " years of experience, the evosa team has the ability to deliver Sap Cloud, AWS Cloud, Google Cloud and any other cloud services tailored to your business.",
          section_procedure_title: "Evosa procedure",
          section_procedure_step_1: "Understanding the Project",
          section_procedure_step_2: "Outline of the Solution",
          section_procedure_step_3: "Proposal and Presentation of Solution",
          section_procedure_step_4: "Developing the Solution",
          section_procedure_step_5:
            "Ecosystem Integration and Solution Release",
          section_procedure_step_6: "Solution Maintenance",
          section_team_title: "Team evosa",
          section_team_description_silviu:
            "As a passionate and proactive software consultant and developer, always bringing innovative solutions, with experience in web development and cloud solutions. I collaborate effectively in teams and am always looking for opportunities to learn and grow personally.",
          section_team_description_anca:
            "Strategic Frontend Consultant. Expertise in optimizing client experiences with advanced tools. Foster collaborative environments for continuous innovation.",
          section_team_description_lorena:
            "As a junior web developer, I am motivated, open-minded, an enthusiastic team player and always willing to accept new challenges and perspectives. In addition, I have a passion for web design and am always looking to improve my skills and knowledge in this area.",
          section_team_description_calin:
            "As a UI/UX developer, I am driven by a relentless passion for creating outstanding user experiences. With a strong work ethic and attention to detail, I consistently tackle complex challenges and find innovative solutions that exceed project requirements.",
          section_team_motto:
            "A small team with big dreams and soon more diverse team members.",
          section_what_means_title: "Evosa means",
          section_what_means_subtitle:
            "Professional team - Quality services - Understanding of customer needs",
          section_what_means_button: "Turning problems into web solutions",
          section_clients_title: "Our clients",
          footer_title_offer: "Request a quote",
          footer_title_contact: "Contact us",
          footer_subtitle_offer:
            "Fill in the form and an evosa specialist will contact you as soon as possible. Many thanks.",
          form_name: "Name",
          form_domains: "Domain",
          form_domain_1: "Business and productivity",
          form_domain_2: "Health & Wellness",
          form_domain_3: "Media & Entertainment",
          form_domain_4: "E-commerce",
          form_domain_5: "Real estate",
          form_domain_6: "Travel and tourism",
          form_domain_7: "Human resources",
          form_domain_8: "Social media",
          form_domain_9: "Transport and logistics",
          form_domain_10: "Education",
          form_domain_11: "Other area",
          form_email: "E-mail",
          form_phone: "Phone",
          form_subject: "Subject",
          form_message: "Your message here...",
          error_message: "Plese complete all the fields.",
        },
      },
    },
  })
