import React, { useEffect } from "react"
import Box from "../reusable-components/box/box"
import Container from "../reusable-components/container/container"
import "./section-what-means-evosa.style.css"
import Text from "../reusable-components/text/text"
import { primaryColor } from "../../constants"
import { Button } from "../reusable-components/buttons/buttons"
import { handleRedirect } from "../../utils/utils"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

function SectionWhatMeansEvosa() {
  const { t } = useTranslation()

  useEffect(() => {
    i18next.changeLanguage("ro")
  }, [])

  return (
    <div className="background-container-what-means-evosa">
      <Container>
        <Box
          flexDirection="column"
          justify="center"
          align="center"
          height="100%"
        >
          <Text
            size="3rem"
            fontWeight={700}
            color={primaryColor}
            margin="0 0 1.5rem 0"
          >
            {t("section_what_means_title")}
          </Text>
          <Text size="1.6rem" fontWeight={200} lineHeight={"30px"}>
            {t("section_what_means_subtitle")}
          </Text>
          <Button
            variant="secondary"
            borderRadius="30px"
            width="auto"
            padding="0 1.5rem 0 1.5rem"
            margin="2.5rem 0 0 0"
            height="2.8rem"
            lineHeight="18px"
            fontWeight={700}
            onClick={() => handleRedirect("footer")}
          >
            {t("section_what_means_button")}
          </Button>
        </Box>
      </Container>
    </div>
  )
}

export default SectionWhatMeansEvosa
