import React, { ReactNode, useEffect, useState } from "react"
import Divider from "../divider/divider"
import Text from "../text/text"
import { primaryColor, secondaryColor } from "../../../constants"
import { Label } from "../label/label"
import { handleResize } from "../../../utils/utils"
import Box from "../box/box"

interface ProgressBarProps {
  currentIndex?: number
  index?: number
  children: ReactNode
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  children,
  index,
  currentIndex,
}) => {
  const step = (index as number) + 1
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isTablet = windowWidth < 780
  const isMobile = windowWidth < 450

  useEffect(() => {
    handleResize(setWindowWidth)
  }, [])

  return (
    <Box
      flexDirection={isTablet ? "row" : "column"}
      justify="flex-start"
      align="flex-start"
    >
      {index === currentIndex ? (
        <Label
          width="0.5rem"
          height="0.5rem"
          borderRadius="30px"
          padding="10px"
          backgroundColor={secondaryColor}
          border="1px solid white"
        >
          {step}
        </Label>
      ) : (
        <Label
          width="0.5rem"
          height="0.5rem"
          borderRadius="30px"
          padding="10px"
          backgroundColor="transparent"
          textColor="transparent"
          border="1px solid white"
        >
          {step}
        </Label>
      )}
      <Divider
        orientation={isTablet ? "vertical" : "horizontal"}
        length={isTablet ? "5.5rem" : "100%"}
        thickness="1.6px"
        color={index === currentIndex ? secondaryColor : "#D8E1E8"}
        margin={isTablet ? "0 1rem 0 0.7rem" : "0.5rem 0 1rem 0"}
      />
      <Text
        align="left"
        lineHeight="25px"
        size="20px"
        width={isTablet ? "68%" : "80%"}
        color={primaryColor}
        fontWeight={index === currentIndex ? 650 : 400}
        margin={isMobile ? "0.5rem 0 1rem 1rem" : "0 1rem 0 0"}
      >
        {children}
      </Text>
    </Box>
  )
}
