import React, { CSSProperties, ReactNode } from "react"

interface FlexProps {
  children: ReactNode
  height?: string
  width?: string
  padding?: string
  align?: "center" | "flex-start" | "flex-end"
  gap?: string
  flexDirection?: "column" | "row-reverse" | "column-reverse" | "row"
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
}

export const Flex: React.FC<FlexProps> = ({
  children,
  padding,
  justify,
  align,
  height,
  width,
  gap,
  flexDirection,
}) => {
  const flexStyle: CSSProperties = {
    display: "flex",
    flexDirection: flexDirection || "row",
    padding: padding || "0",
    justifyContent: justify || "normal",
    alignItems: align || "normal",
    height: height || "auto",
    width: width || "auto",
    gap: gap || "auto",
  }
  return <div style={flexStyle}>{children}</div>
}
