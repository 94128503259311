import i18next, { t } from "i18next"
import React, { useEffect, useState } from "react"
import { primaryColor } from "../../constants"
import { handleRedirect, handleResize } from "../../utils/utils"
import Box from "../reusable-components/box/box"
import { Button } from "../reusable-components/buttons/buttons"
import { Label } from "../reusable-components/label/label"
import Stack from "../reusable-components/stack/stack"
import Text from "../reusable-components/text/text"

export const ServicesComponent = (props: { height: string }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isTablet = windowWidth < 730
  const isMobile = windowWidth < 450
  const [currentIndex, setCurrentIndex] = useState(0)
  const buttonTexts = [
    t("section_services_service_1"),
    t("section_services_service_2"),
    t("section_services_service_3"),
    t("section_services_service_4"),
    t("section_services_service_5"),
  ]

  useEffect(() => {
    handleResize(setWindowWidth)
    i18next.changeLanguage("ro")
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % buttonTexts.length)
    }, 2000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box
      flexDirection={isTablet ? "column" : "row"}
      align="flex-start"
      justify="space-between"
      height={props.height}
      width="100%"
    >
      <Text
        size={isMobile ? "2.2rem" : "3rem"}
        fontWeight={700}
        color={primaryColor}
        align={isTablet ? "center" : "left"}
        width={isTablet ? "100%" : "30%"}
        margin={isTablet ? "0 0 1.5rem 0" : "0 0 0 0"}
      >
        {t("section_services_subtitle_1")} <br></br>
        {t("section_services_subtitle_2")}
      </Text>
      <Box
        flexDirection="column"
        align={isTablet ? "center" : "flex-start"}
        justify="flex-start"
        width={isTablet ? "100%" : "60%"}
        height="100%"
      >
        <Stack gap="0px">
          {buttonTexts.map((text, index) => (
            <Label
              key={index}
              width="90%"
              height="2rem"
              fontSize="18px"
              borderRadius="30px"
              justify="flex-start"
              backgroundColor={
                index === currentIndex ? primaryColor : "transparent"
              }
              durationTransition={500}
            >
              <Text
                align="left"
                color={index === currentIndex ? "white" : "black"}
                fontWeight={index === currentIndex ? 800 : 400}
              >
                {text}
              </Text>
            </Label>
          ))}
        </Stack>

        <Button
          variant="secondary"
          borderRadius="30px"
          width="160px"
          height="2.5rem"
          margin={isTablet ? "2rem 0 0 0" : "2rem 0 0 1.5rem"}
          fontWeight={700}
          onClick={() => handleRedirect("footer")}
        >
          {t("navbar_btn_offer")}
        </Button>
      </Box>
    </Box>
  )
}
