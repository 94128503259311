export const secondaryColor = "#5AC5F4"
export const blue = "#00BCF3"
export const primaryColor = "#132945"

export const imagesClients = [
  {
    path: "/hcl.svg",
    name: "hcl_img",
    domain: "Technology",
  },
  {
    path: "/drivn.svg",
    name: "drivn_img",
    domain: "Automotive",
  },
  {
    path: "/sovanta.svg",
    name: "sovanta_img",
    domain: "Technology",
  },
  {
    path: "/isoSkills.svg",
    name: "iso_img",
    domain: "Outsourcing",
  },

  {
    path: "/devix.svg",
    name: "devix_img",
    domain: "Technology",
  },
  {
    path: "/litrige-blue.png",
    name: "litrige_img",
    domain: "Testing",
  },

  {
    path: "/jtcSolutions.svg",
    name: "jtc_img",
    domain: "Technology",
  },
  {
    path: "/epam.svg",
    name: "epam_img",
    domain: "Technology",
  },
  {
    path: "/paucamelia.png",
    name: "cameliaPau_img",
    domain: "Justice",
  },
  {
    path: "/ntt.svg",
    name: "ntt_img",
    domain: "Technology",
  },
  {
    path: "/logoZMD.png",
    name: "ZMD_img",
    domain: "Horticulture",
  },
]
