import React, { ReactNode } from "react"

interface CardProps {
  height?: string
  width?: string
  padding?: string
  margin?: string
  background?: string
  border?: string
  opacity?: number
  children: ReactNode
}

const Card: React.FC<CardProps> = ({
  height,
  width,
  padding,
  margin,
  background,
  border,
  opacity,
  children,
}) => {
  const cardStyle = {
    height: height || "auto",
    width: width || "100%",
    padding: padding || "10px",
    margin: margin || "0",
    background: background || "#ffffff",
    border: border || "1px solid #dddddd",
    opacity: opacity || 1,
  }

  return <div style={cardStyle}>{children}</div>
}

export default Card
