import i18next from "i18next"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { primaryColor } from "../../constants"
import { handleRedirect, handleResize } from "../../utils/utils"
import Box from "../reusable-components/box/box"
import { Button } from "../reusable-components/buttons/buttons"
import { ProgressBar } from "../reusable-components/progress-bar/progress-bar"
import Text from "../reusable-components/text/text"

const ComponentProcedure: FunctionComponent<{ height: string }> = ({
  height,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const { t } = useTranslation()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isTablet = windowWidth < 780

  const textsProgressBar = [
    t("section_procedure_step_1"),
    t("section_procedure_step_2"),
    t("section_procedure_step_3"),
    t("section_procedure_step_4"),
    t("section_procedure_step_5"),
    t("section_procedure_step_6"),
  ]

  useEffect(() => {
    handleResize(setWindowWidth)
    i18next.changeLanguage("ro")
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textsProgressBar.length)
    }, 2000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box
      height={height}
      width="100%"
      flexDirection="column"
      justify="space-around"
    >
      <Text
        fontWeight={700}
        color={primaryColor}
        size="3rem"
        margin={"0 0 2.5rem 0"}
      >
        {t("section_procedure_title")}
      </Text>
      <Box
        flexDirection={isTablet ? "column" : "row"}
        align="flex-start"
        width="100%"
      >
        {textsProgressBar.map((text, index) => (
          <ProgressBar key={index} index={index} currentIndex={currentIndex}>
            {text}
          </ProgressBar>
        ))}
      </Box>
      <Button
        variant="secondary"
        borderRadius="30px"
        height="2.5rem"
        width="160px"
        margin="2rem 0 0 0"
        fontWeight={700}
        onClick={() => handleRedirect("footer")}
      >
        {t("navbar_btn_offer")}
      </Button>
    </Box>
  )
}

export default ComponentProcedure
