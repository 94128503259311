import React, { ReactNode } from "react"

interface BoxProps {
  children: ReactNode
  width?: string
  height?: string
  flexDirection?: "column" | "row-reverse" | "column-reverse" | "row"
  align?: "center" | "flex-start" | "flex-end"
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
  padding?: string
  gap?: string
}

const Box: React.FC<BoxProps> = ({
  children,
  width,
  height,
  flexDirection,
  align,
  justify,
  padding,
  gap,
}) => {
  const boxStyle = {
    display: "flex",
    flexDirection: flexDirection || "row",
    width: width || "auto",
    height: height || "auto",
    alignItems: align || "center",
    justifyContent: justify || "center",
    padding: padding || "0",
    gap: gap || "auto",
  }

  return (
    <div className="box" style={boxStyle}>
      {children}
    </div>
  )
}

export default Box
