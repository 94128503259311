export const handleRedirect = (section: string) => {
  const zonaTaElement = document.getElementById(section)

  if (zonaTaElement) {
    zonaTaElement.scrollIntoView({ behavior: "smooth" })
  }
}

export const handleResize = (
  setWindowWidth: React.Dispatch<React.SetStateAction<number>>
) => {
  const resize = () => {
    setWindowWidth(window.innerWidth)
  }
  window.addEventListener("resize", resize)
  return () => {
    window.removeEventListener("resize", resize)
  }
}
