import React, { FunctionComponent, useEffect, useState } from "react"
import { primaryColor } from "../../constants"
import { handleResize } from "../../utils/utils"
import Box from "../reusable-components/box/box"
import { Button } from "../reusable-components/buttons/buttons"
import { Flex } from "../reusable-components/flex/flex"
import Stack from "../reusable-components/stack/stack"
import Text from "../reusable-components/text/text"
import "./footer.style.scss"
import emailjs from "@emailjs/browser"
import { useTranslation } from "react-i18next"

export const ContactForm: FunctionComponent<{ widthForm: string }> = ({
  widthForm,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const isTablet = windowWidth < 780
  const isMobile = windowWidth < 450
  useEffect(() => {
    handleResize(setWindowWidth)
  }, [])

  const [name, setName] = useState("")
  const [domain, setDomain] = useState("null")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [responseEmailJS, setResponseEmailJS] = useState("")

  useEffect(() => {
    if (name && domain !== "null" && email && phone && subject && message)
      setErrorMessage("")
  }, [name, domain, email, phone, subject, message])

  const checkInput = (input: string, typeInput?: string) => {
    if (typeInput && typeInput === "textarea") {
      if (errorMessage && input.length === 0) return "error-input-textarea"
      else return "input-textarea"
    } else if (typeInput && typeInput === "select") {
      if (errorMessage && input === "null") return "error-select"
      else return "input-select"
    } else if (errorMessage && input.length === 0) return "error-input"
    else return "input"
  }

  const handleSubmit = (e: React.FormEvent) => {
    setResponseEmailJS("")
    e.preventDefault()
    if (
      !name ||
      domain === "null" ||
      !email ||
      !phone ||
      !subject ||
      !message
    ) {
      setErrorMessage(t("error_message"))
      return
    }

    var templateFormData = {
      userName: name,
      userSubject: subject,
      userMessage: message,
      userDomain: domain,
      userPhone: phone,
      userMail: email,
    }

    emailjs
      .send(
        "service_ax7skkn",
        "template_cpn0ynn",
        templateFormData,
        "user_2nvgisJB3ZQm5tKV432i9"
      )
      .then(
        function (response) {
          setResponseEmailJS("Mesajul a fost trimis cu succes!")
          setDomain("null")
          setName("")
          setEmail("")
          setPhone("")
          setMessage("")
          setSubject("")
          setTimeout(() => {
            setResponseEmailJS("")
          }, 4000)
        },
        function (error) {
          console.error("Eroare!", error)
          setResponseEmailJS("Mesajul nu a fost trimis, vă rog mai încercați!")
        }
      )
  }
  return (
    <Box flexDirection="column" align="flex-start" width={widthForm}>
      <Text
        size="2.5rem"
        fontWeight={700}
        color={primaryColor}
        margin="0 0 2rem 0"
        align={isTablet ? "center" : "left"}
        width="100%"
      >
        {t("footer_title_offer")}
      </Text>

      <Text
        size="1.4rem"
        fontWeight={200}
        width="100%"
        align={isTablet ? "center" : "left"}
        lineHeight="30px"
        margin="0 0 1rem 0"
      >
        {t("footer_subtitle_offer")}
      </Text>
      <form onSubmit={handleSubmit} className={"form-contact-custom"}>
        <Stack align="center">
          <Flex
            width="100%"
            gap="0.5rem"
            flexDirection={isMobile ? "column" : "row"}
          >
            <input
              type="text"
              className={checkInput(name)}
              placeholder={t("form_name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <select
              className={checkInput(domain, "select")}
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            >
              <option value={"null"} style={{ color: "#757575" }}>
                {t("form_domains")}
              </option>
              <option value={"afaceri & productivitate"}>
                {t("form_domain_1")}
              </option>
              <option value={"sănătate& wellness"}>{t("form_domain_2")}</option>
              <option value={"e-commerce"}> {t("form_domain_3")}</option>
              <option value={"media & divertisment"}>
                {t("form_domain_4")}
              </option>
              <option value={"imobiliare"}> {t("form_domain_5")}</option>
              <option value={"turism & călătorii"}>
                {" "}
                {t("form_domain_6")}
              </option>
              <option value={"resurse umane"}> {t("form_domain_7")}</option>
              <option value={"social media"}> {t("form_domain_8")}</option>
              <option value={"transpot & logistică"}>
                {t("form_domain_9")}
              </option>
              <option value={"educație"}> {t("form_domain_10")}</option>
              <option value={"alt domeniu"}> {t("form_domain_11")}</option>
            </select>
          </Flex>
          <Flex
            width="100%"
            gap="0.5rem"
            flexDirection={isMobile ? "column" : "row"}
          >
            <input
              type="email"
              className={checkInput(email)}
              placeholder={t("form_email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className={checkInput(phone)}
              placeholder={t("form_phone")}
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Flex>
          <input
            className={checkInput(subject)}
            placeholder={t("form_subject")}
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <textarea
            className={checkInput(message, "textarea")}
            placeholder={t("form_message")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </Stack>

        {errorMessage ? (
          <Text color="red" margin="2rem 0 0 0">
            {errorMessage ? errorMessage : null}
          </Text>
        ) : null}
        {responseEmailJS ? (
          <Text
            color={responseEmailJS.includes("succes") ? "black" : "red"}
            margin="2rem 0 0 0"
          >
            {responseEmailJS}
          </Text>
        ) : null}

        <Button
          variant="secondary"
          borderRadius="30px"
          width="auto"
          padding="0 2.5rem 0 2.5rem"
          margin="2rem 0 0 0"
          height="2.5rem"
          fontWeight={700}
          type="submit"
        >
          {t("navbar_btn_offer")}
        </Button>
      </form>
    </Box>
  )
}
