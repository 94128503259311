import i18next from "i18next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { primaryColor } from "../../constants"
import { handleResize } from "../../utils/utils"
import Box from "../reusable-components/box/box"
import Card from "../reusable-components/card/card"
import Container from "../reusable-components/container/container"
import Slider from "../reusable-components/slider/slider"
import Stack from "../reusable-components/stack/stack"
import Text from "../reusable-components/text/text"
import "./section-benefits.style.css"

export const SectionBenefits = () => {
  const currentYear = new Date().getFullYear()
  const startYear = new Date(2021, 6, 12).getFullYear()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const isLaptopS = windowWidth < 1200

  useEffect(() => {
    handleResize(setWindowWidth)
    i18next.changeLanguage("ro")
  }, [])

  return (
    <div className="background-container-benefits">
      <Container>
        <Box
          flexDirection="column"
          justify="center"
          align="center"
          height="100%"
        >
          <Text
            size="3rem"
            fontWeight={700}
            color={primaryColor}
            margin="5rem 0 2rem 0"
          >
            {t("section_benefits_title")}
          </Text>
          {isLaptopS ? (
            <Slider />
          ) : (
            <Box flexDirection="row" height="60%" width="100%" gap="1rem">
              <Card
                background="#dae8f563"
                border="1px solid #FBFCFE"
                padding="2rem"
                height="360px"
              >
                <Stack align="flex-start">
                  <img
                    alt="asterix-icon"
                    src="/asterix.png"
                    height="auto"
                    width="70px"
                  />
                  <Text
                    size="1.5rem"
                    fontWeight={650}
                    align="left"
                    color={primaryColor}
                    margin="2rem 0 1rem 0"
                  >
                    {t("section_benefits_card_1_title")}
                  </Text>
                  <Text
                    fontWeight={350}
                    align="left"
                    lineHeight="22px"
                    color="#363636"
                  >
                    {t("section_benefits_card_1_content")}
                  </Text>
                </Stack>
              </Card>
              <Card
                background="#dae8f563"
                border="1px solid #FBFCFE"
                padding="2rem"
                height="360px"
              >
                <Stack align="flex-start">
                  <img
                    alt="code-tag-icon"
                    src="/codeTag.png"
                    height="auto"
                    width="120px"
                  />
                  <Text
                    size="1.5rem"
                    fontWeight={650}
                    align="left"
                    color={primaryColor}
                    margin="2rem 0 1rem 0 "
                  >
                    {t("section_benefits_card_2_title")}
                  </Text>
                  <Text
                    fontWeight={350}
                    align="left"
                    lineHeight="22px"
                    color="#363636"
                  >
                    {t("section_benefits_card_2_content")}
                  </Text>
                </Stack>
              </Card>
              <Card
                background="#dae8f563"
                border="1px solid #FBFCFE"
                padding="2rem"
                height="360px"
              >
                <Stack align="flex-start">
                  <img
                    alt="cloud-icon"
                    src="/cloud.png"
                    height="auto"
                    width="100px"
                  />
                  <Text
                    size="1.5rem"
                    fontWeight={650}
                    align="left"
                    color={primaryColor}
                    margin="2rem 0 1rem 0 "
                  >
                    {t("section_benefits_card_3_title")}
                  </Text>
                  <Text
                    fontWeight={350}
                    align="left"
                    lineHeight="22px"
                    color="#363636"
                  >
                    {t("section_benefits_card_3_content_1")}{" "}
                    {currentYear - startYear}{" "}
                    {t("section_benefits_card_3_content_2")}
                  </Text>
                </Stack>
              </Card>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  )
}
