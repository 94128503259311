/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react"
import Container from "../reusable-components/container/container"
import "./section-one.style.scss"
import { Button } from "../reusable-components/buttons/buttons"
import Box from "../reusable-components/box/box"
import Header from "./header/header"
import { handleRedirect, handleResize } from "../../utils/utils"

export default function SectionOne() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isTablet = windowWidth < 780

  useEffect(() => {
    handleResize(setWindowWidth)
  }, [])

  return (
    <div className="background-container">
      <Header />
      <Container>
        <Box
          flexDirection="column"
          justify={"space-around"}
          height={isTablet ? "100%" : "85%"}
        >
          {windowWidth > 749 ? (
            <div className="css-typing">
              <p>We don`t just code</p>
              <p> / we create solutions.</p>
            </div>
          ) : (
            <div className="css-typing">
              <p>We don`t just </p>
              <p>code / we create </p>
              <p>solutions.</p>
            </div>
          )}

          <Button
            variant="secondary"
            borderRadius="50px"
            width="3rem"
            height="3rem"
            onClick={() => handleRedirect("services")}
          >
            <img
              alt="down-arrow"
              src="/down-arrow.png"
              height="23px"
              width="22px"
            />
          </Button>
        </Box>
      </Container>
    </div>
  )
}
