import React, { ReactNode } from "react"

interface ColoredTextProps {
  color?: string
  size?: string
  children: ReactNode
  fontWeight?: number
  align?: "right" | "center" | "left"
  width?: string
  lineHeight?: string
  margin?: string
}

const Text: React.FC<ColoredTextProps> = ({
  color,
  size = "16px",
  fontWeight = 500,
  children,
  align = "center",
  width = "auto",
  lineHeight = "auto",
  margin = "0px",
}) => {
  const textStyle = {
    color: color,
    fontSize: size,
    fontWeight: fontWeight,
    textAlign: align,
    width: width,
    height: "auto",
    lineHeight: lineHeight,
    margin,
  }

  return <span style={textStyle}>{children}</span>
}
export default Text
