import React, { ReactNode, useEffect, useState } from "react"
import "./label.style.scss"
interface LabelProps {
  fontSize?: string
  width?: string
  height?: string
  border?: string
  borderRadius?: string
  backgroundColor?: string
  durationTransition?: number
  textColor?: string
  fontWeight?: number
  padding?: string
  align?: "center" | "flex-start" | "flex-end"
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
  children: ReactNode
}

export const Label: React.FC<LabelProps> = ({
  fontSize,
  borderRadius,
  children,
  width,
  backgroundColor,
  durationTransition,
  textColor,
  fontWeight,
  height,
  padding,
  border,
  align,
  justify,
}) => {
  const buttonStyle = {
    borderRadius: borderRadius || "5px",
    fontSize: fontSize || "15px",
    width: width || "auto",
    border: border,
    height: height || "auto",
    padding: padding,
    alignItems: align,
    justifyContent: justify,
  }

  const [style, setStyle] = useState({
    backgroundColor,
    color: textColor,
    fontWeight: fontWeight,
    transition: `background-color ${durationTransition}ms`,
  })

  useEffect(() => {
    setStyle({
      backgroundColor: backgroundColor || "transparent",
      color: textColor || "black",
      fontWeight: fontWeight || 400,
      transition: `background-color ${durationTransition}ms`,
    })
  }, [backgroundColor, durationTransition, fontWeight, textColor])
  return (
    <div className="label" style={{ ...buttonStyle, ...style }}>
      {children}
    </div>
  )
}
