/* eslint-disable jsx-a11y/anchor-is-valid */
import i18next from "i18next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { handleRedirect, handleResize } from "../../../utils/utils"
import { Button } from "../../reusable-components/buttons/buttons"
import "./header.style.scss"

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isTablet = windowWidth < 780
  const [isOpen, setIsOpen] = useState(false)
  const [language, setLanguage] = useState("ro")
  const { t } = useTranslation()

  const handleButtonClick = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    handleResize(setWindowWidth)
  }, [])

  useEffect(() => {
    i18next.changeLanguage(language)
  }, [language])

  return isTablet ? (
    <div className="header-tablet-container">
      <img src="/evosa-logo-white.png" alt="Logo" width="auto" height="100%" />
      <>
        <button
          id="burger"
          className={`open-main-nav ${isOpen ? "is-open" : ""}`}
          onClick={handleButtonClick}
        >
          <span className="burger"></span>
          <span className="burger-text">Meniu</span>
        </button>
        <div className={`background-nav ${isOpen ? "is-open" : ""}`}>
          <nav className={`main-nav ${isOpen ? "is-open" : ""}`} id="main-nav">
            <ul>
              <li>
                <a href="#footer" onClick={() => setIsOpen(false)}>
                  {t("navbar_btn_1")}
                </a>
              </li>
              <li>
                <a href="#aboutEvosa" onClick={() => setIsOpen(false)}>
                  {t("navbar_btn_2")}
                </a>
              </li>
              <li>
                <a href="#footer" onClick={() => setIsOpen(false)}>
                  {t("navbar_btn_3")}
                </a>
              </li>
              <li>
                <a href="#footer" onClick={() => setIsOpen(false)}>
                  {t("navbar_btn_offer")}
                </a>
              </li>
              <li>
                <select
                  className="selectLanguage"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value={"ro"}>🇹🇩 Română</option>
                  <option value={"en"}>🇬🇧 English</option>
                </select>
              </li>
            </ul>
          </nav>
        </div>
      </>
    </div>
  ) : (
    <div className="header" style={{ height: "10%" }}>
      <img src="/evosa-logo-white.png" alt="Logo" width="75%" height="auto" />
      <>
        <div></div>
        <Button
          variant="primary"
          fontSize="16px"
          onClick={() => handleRedirect("services")}
        >
          {t("navbar_btn_1")}
        </Button>
        <Button
          variant="primary"
          fontSize="16px"
          onClick={() => handleRedirect("aboutEvosa")}
        >
          {t("navbar_btn_2")}
        </Button>

        <Button
          variant="primary"
          fontSize="16px"
          onClick={() => handleRedirect("footer")}
        >
          {t("navbar_btn_3")}
        </Button>
        <Button
          variant="secondary"
          borderRadius="30px"
          fontSize={isTablet ? "11px" : "13px"}
          width="80%"
          height="2.5rem"
          fontWeight={700}
          onClick={() => handleRedirect("footer")}
        >
          {t("navbar_btn_offer")}
        </Button>

        <select
          className="selectLanguage"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value={"ro"}>🇹🇩 Română</option>
          <option value={"en"}>🇬🇧 English</option>
        </select>
      </>
    </div>
  )
}
