import React from "react"
import Box from "../reusable-components/box/box"
import Container from "../reusable-components/container/container"
import ComponentProcedure from "./component-procedure"
import ComponentTeam from "./component-team"
import "./section-about-evosa.style.css"

export const SectionAboutEvosa = () => {
  return (
    <div className="background-container-about-evosa" id="aboutEvosa">
      <Container>
        <Box
          height="100%"
          flexDirection="column"
          justify="space-between"
          gap="6rem"
        >
          <ComponentProcedure height={"40%"} />
          <ComponentTeam height={"50%"} />
        </Box>
      </Container>
    </div>
  )
}
